import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Tile, { TileHeading } from './Tile';
import GitHub from './svgs/GitHub';
import LinkedIn from './svgs/LinkedIn';

const ProfileColumn = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const ProfileContent = styled(ProfileColumn)`
  flex: 1 1 auto;
  margin-left: 16px;
`;

const ProfilePic = styled.div<{ image: string }>`
  background-image: url('${(props) => props.image}');
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 2px;
  height: 48px;
  width: 48px;
`;

const List = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin-bottom: 16px;
  margin-top: 8px;
  padding-left: 0;
`
const ListItem = styled.li`
  margin-right: 16px;
`;

const SocialLink = styled.a`
  color: #55E4BA;
  text-decoration: none;

  &:hover {
    color: #D78AFB;
  }
`;

interface ProfileProps {
  github: string;
  linkedin: string;
  image: string;
  name: string;
};

const Profile: FunctionComponent<ProfileProps> = ({
  children,
  github,
  linkedin,
  image,
  name,
}) => {
  return (
    <Tile direction="row">
      <ProfileColumn>
        <ProfilePic image={image} />
      </ProfileColumn>
      <ProfileContent>
        <ProfileColumn>
          <TileHeading>{name}</TileHeading>
          {children}
        </ProfileColumn>
        <List>
          <ListItem>
            <SocialLink
              href={`https://www.linkedin.com/in/${linkedin}`}
              target="_blank"
            >
              <LinkedIn fill="currentcolor" />
            </SocialLink>
          </ListItem>
          <ListItem>
            <SocialLink
              href={`https://github.com/${github}`}
              target="_blank"
            >
              <GitHub fill="currentcolor" />
            </SocialLink>
          </ListItem>
        </List>
      </ProfileContent>
    </Tile>
  );
};

export default Profile;
