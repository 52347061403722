import React from 'react';

interface LinkedInProps {
  fill?: string;
  width?: number;
}

const LinkedIn = ({ fill = '#55E4BA', width = 16 }: LinkedInProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.937683 1.14614C0.937683 0.513313 1.43091 0 2.03936 0H14.8387C15.4471 0 15.9404 0.513315 15.9404 1.14614V14.8539C15.9404 15.4869 15.4471 16 14.8387 16L2.03936 16C1.43091 16 0.937683 15.4869 0.937683 14.8539V1.14614ZM5.57294 13.3937V6.16876H3.3212V13.3937H5.57294ZM4.44706 5.18232C5.23229 5.18232 5.72102 4.62753 5.72102 3.9342C5.70639 3.22527 5.23229 2.68587 4.46196 2.68587C3.6917 2.68587 3.18809 3.22527 3.18809 3.9342C3.18809 4.62753 3.67672 5.18232 4.43238 5.18232H4.44706ZM9.04984 13.3937V9.35897C9.04984 9.14304 9.06447 8.92732 9.12393 8.77296C9.28671 8.34153 9.65721 7.89471 10.2793 7.89471C11.0941 7.89471 11.42 8.55725 11.42 9.52851V13.3937H13.6715V9.251C13.6715 7.03184 12.5606 5.99922 11.0792 5.99922C9.88471 5.99922 9.34914 6.69937 9.0499 7.19167V7.21656H9.03486C9.0398 7.20832 9.04482 7.20003 9.0499 7.19167V6.16876H6.79818C6.82773 6.8467 6.79818 13.3937 6.79818 13.3937H9.04984Z" fill={fill} />
    </svg>
  )
}

export default LinkedIn;
