import React from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import Logo from './svgs/Logo';

const Wrapper = styled.div<{ isOpen: boolean }>`
  background: #2D2D2D;
  box-shadow: ${(props) => props.isOpen
    ? '0 2px 4px rgba(0, 0, 0, 0.4)'
    : 'none'
  };
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  position: fixed;
  left: ${props => props.isOpen ? 0 : '-175px'};
  transition: left 0.25s ease-in-out, visibility 0.25s linear;
  top: 0;
  visibility: ${props => props.isOpen ? 'visible': 'hidden'};
  width: 175px;
  z-index: 999;

  @media (min-width: 992px) {
    box-shadow: none;
  }
`;

const Inner = styled.div`
  padding-top: 47px;
  position: relative;

  &::after {
    @media (min-width: 992px) {
      border-right: 1px solid #383838;
      bottom: -50px;
      content: '';
      position: absolute;
      right: 0;
      top: 0;
    }
  }
`;

const Close = styled.div`
  cursor: pointer;
  height: 16px;
  position: absolute;
  right: 0;
  top: 50px;
  width: 16px;

  &:hover {
    &::before,
    &::after {
      background-color: #55E4BA;
    }
  }

  &::before,
  &::after {
    background-color: #ECECEC;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    width: 21px;
  }

  &::before {
    top: 0;
    transform: rotate(45deg);
    transform-origin: top left;
  }

  &::after {
    bottom: 0;
    transform: rotate(-45deg);
    transform-origin: bottom left;
  }

  @media (min-width: 992px) {
    display: none;
  }
`;

const Nav = styled.nav`
  margin-top: 96px;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  margin-bottom: 24px;
`;

const ListLink = styled(Link)`
  color: #ECECEC;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    color: #55E4BA;
    text-decoration: none;
  }
`;

interface MenuProps {
  onClose: () => void;
  open: boolean;
}

const Menu = ({ onClose, open }: MenuProps) => {
  return (
    <Wrapper isOpen={open}>
      <Inner>
        <Logo width={80} />
        <Close onClick={onClose} />
        <Nav>
          <List>
            <ListItem>
              <ListLink
                activeClass="active"
                spy={true}
                smooth={true}
                duration={500}
                to="top"
              >
                Home
              </ListLink>
            </ListItem>
            <ListItem>
              <ListLink
                activeClass="active"
                spy={true}
                smooth={true}
                duration={500}
                to="about"
              >
                About
              </ListLink>
            </ListItem>
            <ListItem>
              <ListLink
                activeClass="active"
                spy={true}
                smooth={true}
                duration={500}
                to="services"
              >
                Services
              </ListLink>
            </ListItem>
            <ListItem>
              <ListLink
                activeClass="active"
                spy={true}
                smooth={true}
                duration={500}
                to="contact"
              >
                Contact
              </ListLink>
            </ListItem>
          </List>
        </Nav>
      </Inner>
    </Wrapper>
  )
}

export default Menu;
