import React from 'react';

interface GitHubProps {
  fill?: string;
  width?: number;
}

const GitHub = ({ fill = '#55E4BA', width = 16 }: GitHubProps) => {
  return (
    <svg width={width} height={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.44447 0C4.29997 0 0.943115 3.58 0.943115 8C0.943115 11.54 3.09038 14.53 6.07217 15.59C6.44723 15.66 6.58788 15.42 6.58788 15.21C6.58788 15.02 6.57851 14.39 6.57851 13.72C4.69379 14.09 4.2062 13.23 4.05618 12.78C3.97179 12.55 3.6061 11.84 3.28729 11.65C3.02474 11.5 2.64967 11.13 3.27791 11.12C3.86864 11.11 4.2906 11.7 4.43125 11.94C5.10637 13.15 6.18469 12.81 6.61602 12.6C6.68165 12.08 6.87856 11.73 7.09423 11.53C5.42517 11.33 3.68111 10.64 3.68111 7.58C3.68111 6.71 3.97179 5.99 4.45 5.43C4.37499 5.23 4.11244 4.41 4.52501 3.31C4.52501 3.31 5.15325 3.1 6.58788 4.13C7.18799 3.95 7.82561 3.86 8.46322 3.86C9.10084 3.86 9.73845 3.95 10.3386 4.13C11.7732 3.09 12.4014 3.31 12.4014 3.31C12.814 4.41 12.5515 5.23 12.4764 5.43C12.9547 5.99 13.2453 6.7 13.2453 7.58C13.2453 10.65 11.4919 11.33 9.82284 11.53C10.0948 11.78 10.3292 12.26 10.3292 13.01C10.3292 14.08 10.3198 14.94 10.3198 15.21C10.3198 15.42 10.4605 15.67 10.8355 15.59C12.3247 15.0539 13.6187 14.0332 14.5354 12.6716C15.4521 11.3101 15.9454 9.6762 15.9458 8C15.9458 3.58 12.589 0 8.44447 0Z" fill={fill} />
    </svg>
  )
}

export default GitHub;
