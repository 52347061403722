import React, { FunctionComponent } from 'react';
import { Element } from 'react-scroll';
import styled from 'styled-components';

const SectionWrapper = styled.div`
  margin-bottom: 64px;
`;

const SectionName = styled.h2`
  color: #D78AFB;
  font-size: 26px;
  margin: 0 0 16px;
`;

export const SectionIntro = styled.div`
  margin-bottom: 35px;
`;

interface SectionProps {
  anchor: string;
  name: string;
}

const Section: FunctionComponent<SectionProps> = ({
  anchor,
  children,
  name,
}) => {
  return (
    <SectionWrapper>
      <Element name={anchor}></Element>
      <SectionName>{name}</SectionName>
      {children}
    </SectionWrapper>
  );
};

export default Section;
