import React from 'react';

interface LogoProps {
  fill?: string;
  width?: number;
}

const Logo = ({ fill = 'white', width = 278 }: LogoProps) => {
  const height = width * 0.27;

  return (
    <svg width={width} height={height} viewBox="0 0 278 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M97.2972 10.7143C97.2972 16.6316 78.6928 21.4286 55.7432 21.4286C32.7935 21.4286 14.1891 16.6316 14.1891 10.7143C14.1891 4.79695 32.7935 0 55.7432 0C78.6928 0 97.2972 4.79695 97.2972 10.7143ZM111.486 53.5715C111.486 65.4062 86.5293 75 55.7432 75C24.9571 75 0 65.4062 0 53.5715C0 41.7368 24.9571 32.1429 55.7432 32.1429C86.5293 32.1429 111.486 41.7368 111.486 53.5715ZM199.523 18.8486C199.523 21.9153 196.705 24.4013 193.23 24.4013C189.754 24.4013 186.937 21.9153 186.937 18.8486C186.937 15.7819 189.754 13.2959 193.23 13.2959C196.705 13.2959 199.523 15.7819 199.523 18.8486ZM188.048 30.0992H198.413V67.1173H188.048V30.0992ZM251.695 44.5241C251.919 41.1592 254.761 37.2708 259.92 37.2708C265.603 37.2708 267.996 40.8601 268.146 44.5241H251.695ZM269.118 54.0956C267.922 57.3857 265.379 59.7038 260.743 59.7038C255.808 59.7038 251.695 56.1893 251.471 51.3288H277.792C277.792 51.2922 277.801 51.1748 277.815 50.9975C277.856 50.451 277.942 49.3357 277.942 48.263C277.942 36.4483 271.137 29.1949 259.771 29.1949C250.349 29.1949 241.675 36.8222 241.675 48.5621C241.675 60.975 250.573 68.2283 260.668 68.2283C269.716 68.2283 275.549 62.9192 277.418 56.5632L269.118 54.0956ZM241.379 30.3168H230.911L222.162 54.993L213.039 30.3168H202.122L217.152 67.1069H227.097L241.379 30.3168ZM181.656 67.1069V14.0903H171.262V35.4016H148.156V14.0903H137.837V67.1069H148.156V45.1226H171.262V67.1069H181.656Z" fill={fill} />
    </svg>
  )
}

export default Logo;
